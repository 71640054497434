$primary: #FFAF1D;
$secondary: rgba(173, 173, 173, 1);
$theme-primary: $primary;
$theme-primary-dark: #D78C04;
$theme-primary-light: #FFBF4B;
$text-primary: #FAFAFA;
$text-primary-alt: #DDD;
$text-secondary: $secondary;
$divider: #3D3D3D;
$bg-primary: #161616;
$bg-paper1: rgba(37, 37, 37, 0.8);
$bg-paper2: rgba(22, 22, 22, 0.5);
$bg-input: rgba(38, 38, 38, 0.5);


@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/glider-js/glider.min";
@import "node_modules/glightbox/dist/css/glightbox.min";

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

// Fonts
h1 { font-weight: 700; font-size: 78px; }
h2 { font-weight: bold; font-size: 56px; margin-bottom: 1.5rem; }
h3 { font-weight: normal; margin: 0; }
h4 { font-weight: 400; }
.text-large { font-size: 22px; }
.text-small { font-size: 12px; }
.text-weight-300 { font-weight: 300; }
.text-weight-400 { font-weight: 400; }
.text-weight-500 { font-weight: 500; }
.text-weight-600 { font-weight: 600; }
.text-weight-700 { font-weight: 700; }
.line-height-reset { line-height: initial; }
.font-size-18 { font-size: 18px; }
.font-size-20 { font-size: 20px; }
.font-size-22 { font-size: 22px; }
.font-size-24 { font-size: 24px; }
.text-primary { color: $text-primary !important; }

p, h1, h2, h3, h4, a, span, div, button { font-family: "Space Grotesk", sans-serif; letter-spacing: 0.4px; transition: all .15s; }
h1, h2, h3, h4 { color: $text-primary; }
body { background-color: #181716 !important; color: $text-secondary; font-size: 20px; font-weight: 400; }
img { max-width: 100%; }
a { text-decoration: none; }
.section { margin: 3rem 0; }
.logo { width: 150px; }
h1 .highlight { background-color: rgba(255,255,255,.05); }
ul { list-style: none; padding-left: 0; }
.title-img { width: 85px; }
.inline-img { position: relative; top: -3px; }

// Page
.exc-bg { height: 100vh; min-height: 800px; opacity: 0.1; width: 100%; background: url("../img/bg-top.min.svg") top left no-repeat, url("../img/bg-bottom.min.svg") bottom right no-repeat; background-size: contain; position: fixed; z-index: -1; }
.page { padding-top: 30px; }

// Navbar
nav.navbar { backdrop-filter: blur(20px); background-color: rgba(255,255,255,.01) }
@supports not (backdrop-filter: blur(12px)) { nav.navbar { background: rgba(22, 22, 22, 0.9); } }
.nav-link { font-size: 18px; color: $text-secondary; font-weight: 500; padding: 0 20px !important; }
.nav-link.highlight { color: $text-primary;  }
.nav-link:hover, .nav-link:focus { color: $text-primary; font-weight: 700; }
.navbar-toggler:hover, .navbar-toggler:focus { box-shadow: none; }
.navbar-toggler:hover svg line, .navbar-toggler:focus svg line { stroke: $primary; }

// Buttons
.btn { border-width: 2px; border-radius: 4px; color: $text-primary; }
.btn.btn-big { font-size: 18px; font-weight: 700; }
.btn.btn-primary { color: $text-primary; }
.btn.btn-primary[disabled="disabled"], .btn.btn-secondary[disabled="disabled"] { color: $text-secondary; border-color: $bg-input; background: $bg-input; }
.btn.btn-secondary { background-color: transparent; color: $theme-primary; border-color: $theme-primary; }
.btn.btn-light { background-color: transparent; color: $text-primary-alt; border-color: $divider; }
.btn.btn-icon { font-size: 22px; padding: 0; color: $text-secondary; box-shadow: none !important; }
.btn.btn-secondary:hover, .btn.btn-secondary:focus { background-color: $primary; color: $text-primary; }
.btn.btn-icon:hover, .btn.btn-icon:focus { color: $text-primary; }
.btn.btn-light:hover, .btn.btn-light:focus { background-color: rgba(61,61,61,.5); }
.btn.btn-unstyled { border: none; background-color: transparent; }
.btn.btn-unstyled.btn-primary { color: $theme-primary; }
.btn.btn-unstyled.btn-secondary { color: $text-secondary; }
.btn.btn-unstyled.btn-primary:hover, .btn.btn-unstyled.btn-primary:focus { color: $theme-primary-light; }
.btn.btn-unstyled.btn-secondary:hover, .btn.btn-unstyled.btn-secondary:focus { color: $theme-primary; background: transparent !important; }

// Cards
.card { margin: 15px 0; padding: 30px; border-radius: 4px; backdrop-filter: blur(12px); background: $bg-paper2; border: 1px solid rgba(50, 46, 45, 0.8); }
@supports not (backdrop-filter: blur(12px)) { .card { background: rgba(22, 22, 22, 0.9); } }
.card.card-no-border { border: none; background-color:rgba(255,255,255,.01) }

// Intro
.v-sword-bg { background: url('../img/v-sword.svg') center center no-repeat; background-size: contain; padding: 150px 0 60px; }

// Misc
.announcement { font-size: 28px; }
.introduction { font-size: 20px; }

// Global data
.data-entry .data-entry-value { font-size: 38px; }
.data-entry .data-entry-label { font-size: 20px; }

// Block links
.block-link .card { background: url('../img/ellipse.svg') left top no-repeat; }
.block-link:hover .card { background: url('../img/ellipse.svg') left top no-repeat, #362F2D; }
.block-link .card img { top: -35px !important; transition: all .5s; }

// Footer
footer { background-color: #161612; border-top: 1px solid $divider; }
footer svg { fill: $text-secondary; }
footer svg:hover { fill: $primary; }
footer .catchphrase { font-size: 15px; }
footer ul li { margin-bottom: 15px; }
footer ul li a { color: $text-primary; }
footer ul li a:hover { color: $primary; }


// Glider
.glider-prev { padding-right: 30px; }
.glider-dots { margin-top: 30px; }
.glider-dot.active { background: $primary; }
.glider-slide img { padding: 1rem; }

// Glightbox
.gslide-image { background-color: #161612; }
.gslide-image img { padding:3rem; max-height: 97vh; max-width: 100%; }
.gslide .gdesc-inner { background-color: #161612; }
.gslide .gslide-title { color: $primary; margin-bottom: 5px; text-align: center; }
.gslide .gslide-desc { color: $text-secondary; max-width: 900px; margin: auto; }

@include media-breakpoint-down(xl) {
    body { font-size: 18px; }
    h1 { font-size: 56px; }
    h2 { font-size: 44px; }
    .exc-bg { opacity: 0.05; }
    .title-img { width: 70px; }
    .btn.btn-big { font-size: 18px; }
}
@include media-breakpoint-down(lg) {
    body { font-size: 16px; }
    .page { padding-top: 70px; }
    .section { margin: 5rem 0; }
    h2 { font-size: 40px; }
    .title-img { width: 60px; }
    .introduction { font-size: 18px; }
    .announcement { font-size: 21px; }
    .v-sword-bg { padding: 100px 0 40px; }
}
@include media-breakpoint-down(md) {
    .title-img { width: 50px; }
}
@include media-breakpoint-down(sm) {
    .section { margin: 5rem 0; }
    h1 { font-size: 38px; }
    h2 { font-size: 32px; }
    .announcement { font-size: 18px; }
    .btn.btn-big, .introduction { font-size: 15px; }
    footer { font-size: 13px; }
    footer .catchphrase { font-size: 11px; }
}

@include media-breakpoint-up(md) {
    .block-link:hover .card img { transform-origin: center bottom; transform: rotateY(-180deg) translateX(-500%); }
}